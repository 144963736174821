import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import Loader from 'react-loaders'

const Home = () => {
const [letterClass, setLetterClass] = useState('text-animate')

const nameArray = "Braden McGee".split("")
const jobArray = "UX Researcher".split("")

useEffect(() => {
    setTimeout(() => {
        setLetterClass('text-animate-hover')
    }, 4000)
}, [])

    return (
        <>
        <div className = 'container home-page'>
            <div className = 'text-zone'>
            <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,&nbsp;</span>
            
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m </span>
                <br/>
                <AnimatedLetters  letterClass = {letterClass}
                strArray = {nameArray}
                idx = {15}/>
                
                <br />
                <AnimatedLetters  letterClass = {letterClass}
                strArray = {jobArray}
                idx = {22}/>
                </h1>
                <h2>Graphic Design / User Research / Frontend Development</h2>
                <Link to='/contact' className = 'flat-button'>CONTACT ME</Link>
            </div>
            < Logo />
        </div>
        <Loader type = "pacman"/>
        </>
    )
}

export default Home