import {faBehanceSquare, faCss3, faFigma,faHtml5, faJsSquare, faReact } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FileSaver from "file-saver"
import { useEffect, useState } from 'react'
import Loader from "react-loaders"
import AnimatedLetters from "../AnimatedLetters"
import './index.scss'


const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

useEffect(() => {
    setTimeout(() => {
    setLetterClass('text-animate-hover')
    }, 3000);
}, []);

/* const onButtonClick = () => {
    fetch('BradenM Resume.pdf').then(response => {
        response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'BradenM Resume.pdf';
            alink.click();
        })
    })
} */

    return (
        <>
        <div className = 'container about-page'>
            <div className = 'text-zone'>
                <h1>
                <AnimatedLetters
                letterClass = {letterClass}
            strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
            idx={15}
            />
                </h1>
                <p>
                I am an ambitious UX researcher looking for a role with established companies with the opportunity to do research and work with clients to make user-interface interactions as smooth as possible.
                </p>
                <p>
                I am a hard worker, naturally curious, and continuously work on developing my skills to better my expertise.
                </p>
                <p>
                In my off time, I enjoy bouldering, spending time with my family, biking around the city, and volunteering for organizations I care about.
                </p>
                {/* <p>
                    If interested in contacting me, here's my resume for your convenience. <br />
                    <button className = "flat-button" onClick={onButtonClick}>Resume</button>
                </p> */}
            </div>
            <div className = 'stage-cube-cont'>
                <div className = 'cubespinner'>
                    <div className = 'face1'>
                        <FontAwesomeIcon icon = {faFigma} color = "#DD0031"/>
                    </div>
                    <div className = 'face2'>
                        <FontAwesomeIcon icon = {faHtml5} color = "#F06529"/>
                    </div>
                    <div className = 'face3'>
                        <FontAwesomeIcon icon = {faCss3} color = "#28A4D9"/>
                    </div>
                    <div className = 'face4'>
                        <FontAwesomeIcon icon = {faReact} color = "#5ED4F4"/>
                    </div>
                    <div className = 'face5'>
                        <FontAwesomeIcon icon = {faJsSquare} color = "#EFD81D"/>
                    </div>
                    <div className = 'face6'>
                        <FontAwesomeIcon icon = {faBehanceSquare} color = "#EC4D28"/>
                    </div>
                </div>
            </div>
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default About