import './App.scss'
import {  Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Portfolio from './components/Portfolio'
import Sidebar from './components/Sidebar'

function App() {
  return (
    <div>
      <Sidebar />
      <Routes>
        <Route exact path="/" element={< Layout />}>
        <Route exact index element={ < Home />}/>
        <Route exact path="about" element={< About />}/>
        <Route exact path="/contact" element={< Contact />}/>
        <Route exact path="/portfolio" element={< Portfolio />}/>
        </Route>
      </Routes>
    </div>
  );
  }

export default App
