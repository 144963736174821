import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm (
            'default_service',
            'template_xhkd8yo',
            form.current,
            'mMqQYNr9WEt80rcQH'
        )
        .then (() => {
            alert ('Message successfully sent!')
            window.location.reload(false)
        },
        () => {
            alert ('Failed to send the message, please try again.')
        }
        )
    }

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const contactArry = "Contact Me".split("")

    return (<>
    <div className='container contact-page'>
        <div className = 'text-zone'>
            <h1>
                <AnimatedLetters
                letterClass = {letterClass}
                strArray= {contactArry}
                idx = {15} />
            </h1>
            <p>
                I am interested in freelance opportunities as well as full time job opportunities. If you have any requests or questions, please contact me using the form below.
            </p>
            <div className = 'contact-form'>
            <form ref = {form} onSubmit={sendEmail}>
                    <ul>
                        <li className = 'half'>
                            <input type='text' name='name' placeholder = 'Name' required/>
                        </li>
                        <li className = 'half'>
                            <input type='email' name='email' placeholder = 'Email' required/>
                        </li>
                        <li>
                            <input placeholder = 'Subject' type = 'text' name = 'subject' required />
                        </li>
                        <li>
                            <textarea placeholder = 'Message' name = 'message' required/>
                        </li>
                        <li>
                            <input type = 'submit' className = 'flat-button' value = 'SEND' />
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        <div className = 'info-map'>
            Braden McGee
            <br/>
            Seattle,
            Washington <br/>
            <span>mcgeek31@gmail.com</span>
        </div>
        <div className = 'map-wrap'>
    <MapContainer center = {[47.600809, -122.301583]} zoom = {12}>
        <TileLayer url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
    </MapContainer>
        </div>

        <Loader type = 'pacman'/>
    </div>
    </>)
}

export default Contact